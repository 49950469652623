import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { Layout, SEO } from 'components'

const useStyles = makeStyles(theme => ({
  content: {
    fontWeight: 'bold',
  },
  mainContainer: {
    paddingTop: '50px',
    paddingLeft: '100px',
    paddingRight: '100px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '20px',
      paddingLeft: '32px',
      paddingRight: '32px',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '20px',
      paddingLeft: '44px',
      paddingRight: '44px',
    },
  },
  tittle: {
    color: theme.palette.text.gray,
    fontWeight: 'bold',
    marginBottom: '4px',
    marginTop: '4px',
    fontSize: '1.5rem',
  },
}))

const ContactUsPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Contact us" />
      <div className={classes.mainContainer}>
        <Typography className={classes.tittle}>Contact Us</Typography>
        <Typography className={classes.content}>
          <ul>
            <li>Support@smthy.app</li>
            <li>P.O. Box 332 Culver City, California 90232</li>
          </ul>
        </Typography>
      </div>
    </Layout>
  )
}

export default ContactUsPage
